import React, { useMemo } from 'react';

import type { ManipulateType } from 'dayjs';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, DateInput as DatePickerInput, Icon, Input } from '@tf/ui';
import { D, fmt } from '@tf/utils';

import { useFormStore, useSegmentContext } from '../../../hooks';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export function DateInput({
	name,
	label,
	description,
	placeholder,
	className,
	isRequired,
	isReadOnly,
	tooltip,
}: DefaultInputProps) {
	const form = useFormContext();
	const { identity } = useSegmentContext();
	const rules = useFormStore((s) => s.getFieldValidationRules(identity.segmentKind, name));

	// TODO: Move rules to a separate hook
	const maxDate = useMemo<Date | undefined>(() => {
		const minDateRule = rules.find(({ rule }) => rule === 'minRelativeDate');
		if (minDateRule) {
			const { direction, unit, amount } = fmt.toRelativeDateNotation(minDateRule.value as string);
			if (direction === 'before') {
				return dayjs().subtract(amount, unit).toDate();
			}
			if (direction === 'after') {
				return dayjs().add(amount, unit).toDate();
			}
		}
	}, [rules]);

	const minDate = useMemo<Date | undefined>(() => {
		const maxDateRule = rules.find(({ rule }) => rule === 'maxRelativeDate');
		if (maxDateRule) {
			const { direction, unit, amount } = fmt.toRelativeDateNotation(maxDateRule.value as string);
			if (direction === 'before') {
				return dayjs()
					.subtract(amount, unit as ManipulateType)
					.toDate();
			}
			if (direction === 'after') {
				return dayjs()
					.add(amount, unit as ManipulateType)
					.toDate();
			}
		}
	}, [rules]);

	return (
		<Box id={name}>
			<Controller
				control={form.control}
				name={name}
				render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
					<Input.Wrapper
						id={name}
						className={className}
						label={label}
						labelProps={{ required: isRequired }}
					>
						<DatePickerInput
							id={name}
							value={typeof value === 'string' ? D.jsDateFromString(value) : undefined}
							ref={ref}
							description={description}
							error={Boolean(error)}
							variant="filled"
							valueFormat="DD.MM.YYYY"
							dateParser={(value) => dayjs(value, 'DD.MM.YYYY').toDate()}
							defaultLevel="year"
							locale="en"
							readOnly={isReadOnly}
							minDate={minDate}
							maxDate={maxDate}
							placeholder={placeholder || isReadOnly ? 'Date unspecified' : 'Select Date...'}
							onChange={(v) => {
								const nextValue = v ? fmt.toDate(v, { preset: 'date_struct' }) : undefined;
								onChange(nextValue);
							}}
							onBlur={onBlur}
							popoverProps={{ position: 'top-start' }}
							rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
							icon={!isReadOnly && <Icon.IconCalendar size={16} />}
						/>
					</Input.Wrapper>
				)}
			/>
		</Box>
	);
}
