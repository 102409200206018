import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Button, createStyles, Flex, Input, TextInput, TFText } from '@tf/ui';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

const useStyles = createStyles(({ colors }) => ({
	disabledSelectedButton: {
		'&:disabled': {
			borderColor: colors.brand[1],
			backgroundColor: colors.brand[1],
			color: colors.dark[3],
		},
	},
}));

export default function CheckboxInput({
	name,
	label,
	isReadOnly,
	className,
	isRequired,
	tooltip,
}: DefaultInputProps) {
	const { classes } = useStyles();
	const { control, getValues, setValue } = useFormContext();

	useEffect(() => {
		setValue(name, getValues(name) ?? false);
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<Input.Wrapper
					labelProps={{ style: { display: 'flex' } }}
					className={className}
					label={
						<Flex align={'center'}>
							<TFText>{label}</TFText>
							<TooltipIcon style={{ margin: '0 2px 0 4px' }} tooltip={tooltip} />
						</Flex>
					}
					error={Boolean(error)}
					required={isRequired}
				>
					{!isReadOnly ? (
						<Button.Group>
							<Button
								id={`${name}-no`}
								className={value === true && isReadOnly ? classes.disabledSelectedButton : ''}
								variant={value === true ? 'filled' : 'outline'}
								size="xs"
								onClick={() => onChange(true)}
								disabled={isReadOnly}
							>
								Yes
							</Button>
							<Button
								id={`${name}-yes`}
								className={value === false && isReadOnly ? classes.disabledSelectedButton : ''}
								variant={value === false ? 'filled' : 'outline'}
								size="xs"
								onClick={() => onChange(false)}
								disabled={isReadOnly}
							>
								No
							</Button>
						</Button.Group>
					) : (
						<TextInput value={value ? 'Yes' : 'No'} readOnly />
					)}
				</Input.Wrapper>
			)}
		/>
	);
}
