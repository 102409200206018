import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Box, createStyles, DateInput as DatePickerInput, Input, Stack, TextInput } from '@tf/ui';
import { D, fmt, type IndividualBasicAccountData } from '@tf/utils';

import { SearchButtons } from './index';

const useStyles = createStyles(({ radius }) => ({
	root: {
		borderRadius: radius.sm,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'default',
		justifyContent: 'space-between',
		backgroundColor: '#eef4ff',
	},
	title: {
		fontWeight: 500,
		display: 'flex',
	},
	body: {},
	textInput: {},
}));

interface Props {
	initialValue?: IndividualBasicAccountData;
	submit: (data: {
		first_name: string;
		last_name: string;
		middle_name?: string;
		birth_date?: string;
	}) => void;
	close: () => void;
	isReadOnly: boolean;
}

export const IndividualSearchForm: React.FC<Props> = ({ initialValue, submit, close, isReadOnly }) => {
	const { classes } = useStyles();

	const { control, handleSubmit, formState } = useForm<{
		first_name: string;
		last_name: string;
		middle_name?: string;
		birth_date?: string;
	}>({
		defaultValues: {
			first_name: initialValue?.first_name ?? '',
			last_name: initialValue?.last_name ?? '',
			middle_name: initialValue?.middle_name ?? '',
			birth_date: initialValue?.birth_date ?? undefined,
		},
		mode: 'onSubmit',
	});

	const onSubmit = handleSubmit((data) => {
		if (Object.values(formState.errors).length === 0) {
			submit(data);
			close();
		}
	});

	return (
		<Box className={classes.root}>
			<Stack spacing={12} className={classes.body}>
				<Controller
					name="first_name"
					control={control}
					rules={{ required: true }}
					render={({ field, fieldState }) => (
						<Input.Wrapper label="First name" labelProps={{ required: true }}>
							<TextInput disabled={isReadOnly} {...field} error={Boolean(fieldState.error)} />
						</Input.Wrapper>
					)}
				/>
				<Controller
					name="last_name"
					control={control}
					rules={{ required: true }}
					render={({ field, fieldState }) => (
						<Input.Wrapper label="Last name" labelProps={{ required: true }}>
							<TextInput disabled={isReadOnly} {...field} error={Boolean(fieldState.error)} />
						</Input.Wrapper>
					)}
				/>
				<Controller
					name="middle_name"
					control={control}
					render={({ field, fieldState }) => (
						<TextInput
							disabled={isReadOnly}
							label="Middle name"
							{...field}
							error={Boolean(fieldState.error)}
						/>
					)}
				/>

				<Controller
					name="birth_date"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Input.Wrapper label="Date of birth">
							<DatePickerInput
								disabled={isReadOnly}
								value={typeof value === 'string' ? D.jsDateFromString(value as string) : undefined}
								onChange={(v) => {
									const nextValue = v ? fmt.toDate(v, { preset: 'date_struct' }) : null;
									onChange(nextValue);
								}}
								variant="filled"
								valueFormat="DD.MM.YYYY"
								locale="en"
								placeholder={'Select Date...'}
								popoverProps={{ zIndex: 1001, position: 'bottom' }}
								error={Boolean(error)}
							/>
						</Input.Wrapper>
					)}
				/>

				<SearchButtons isReadOnly={isReadOnly} close={close} submit={onSubmit} />
			</Stack>
		</Box>
	);
};
