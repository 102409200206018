import React from 'react';

import { Alert, createStyles, Group, Stack, TFText } from '@tf/ui';

import type { IdenfyJobResult } from '../../../../types';
import { IdenfyCheckResult } from '../../../../types';

import { DocumentsCheckIdenfyResult } from './DocumentsCheckIdenfyResult';
import type { DocumentCheckJob } from './types';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.brand[6],
	},
}));

const getJobResultMessage = (result: IdenfyCheckResult) => {
	switch (result) {
		case IdenfyCheckResult.APPROVED:
			return {
				status: 'APPROVED',
				text: 'There are no indications the document is fraudulent.',
				color: '#2F9E3F',
			};
		case IdenfyCheckResult.SUSPECTED:
			return {
				status: 'SUSPECTED',
				text: 'Document shows signs of suspect fraud.',
				color: '#FA5252',
			};
		case IdenfyCheckResult.DENIED:
			return {
				status: 'DENIED',
				text: "We can't process the document image, or the document isn't supported for processing.",
				color: '#FA5252',
			};
		case IdenfyCheckResult.EXPIRED:
		case IdenfyCheckResult.ACTIVE:
		default:
			return {
				status: 'UNIDENTIFIED',
				text: "We can't successfully complete all verifications.",
				color: '#344054',
			};
	}
};

interface Props {
	job: DocumentCheckJob;
}

export const DocumentsCheckGenericResult: React.FC<Props> = ({ job }) => {
	const { classes } = useStyles();

	const jobResult = (job.result as any).result as IdenfyJobResult;
	const status = jobResult.status?.overall;

	if (job.payload.serviceKind === 'idenfy') {
		return (
			<Group className={classes.infoBlockWrapper}>
				<Stack spacing="xs">
					<TFText className={classes.infoBlockContent} weight={500}>
						Document check results:{' '}
						<span style={{ color: getJobResultMessage(status).color }}>
							{getJobResultMessage(status).status}
						</span>
					</TFText>
					<div style={{ color: getJobResultMessage(status).color }}>
						{getJobResultMessage(status).text}
					</div>
				</Stack>
				<DocumentsCheckIdenfyResult job={job} />
			</Group>
		);
	}

	return (
		<Alert w="100%" color="red">
			Unknown service provider
		</Alert>
	);
};
